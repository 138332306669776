import { BehaviorSubject, map, Observable, take, tap } from 'rxjs';
import { BookingService } from '@app/api/booking.service';
import { StoryService } from '@app/api/story.service';
import { BookingSimple } from '@shared/models/members-area/booking-simple.model';
import { Story } from '@shared/models/members-area/story.model';
import { PublicStatusType } from '@shared/models/public-status-type.model';
import { effect, Injectable } from '@angular/core';
import { ServerActionsStore, ServerActionType } from '@ess-front/shared';
import { BookingReadyServerAction } from '@shared/models/booking-ready-server-action.model';
import { TripService } from '@api/trip.service';

@Injectable({
  providedIn: 'root',
})
export class MemberDomainService {
  private tripsSubject$ = new BehaviorSubject<BookingSimple[]>([]);

  readonly futureTrips$: Observable<BookingSimple[]> = this.tripsSubject$
    .asObservable()
    .pipe(map(bookings => bookings.filter(booking => this.isFutureTrip(booking)).reverse()));

  readonly pastTrips$: Observable<BookingSimple[]> = this.tripsSubject$.asObservable().pipe(
    map(bookings => {
      return bookings.filter(booking => this.isPastTrip(booking));
    }),
  );

  readonly upcomingTrips$: Observable<BookingSimple[]> = this.tripsSubject$.asObservable().pipe(
    map(bookings => {
      return bookings.filter(booking => this.isUpcomingTrip(booking)).reverse();
    }),
  );

  constructor(
    private bookingService: BookingService,
    private serverActionsStore: ServerActionsStore,
    private storyService: StoryService,
    private tripService: TripService,
  ) {
    effect(() => {
      const serverAction = this.serverActionsStore.lastServerAction() as BookingReadyServerAction | null;
      this.updateTripIfBookingReadyServerAction(serverAction);
    });
  }

  getStories$(): Observable<Story[]> {
    return this.storyService.getStories$();
  }

  unloadTrips(): void {
    this.tripsSubject$.next([]);
  }

  loadTrips$(): Observable<void> {
    return this.bookingService.getTrips$().pipe(
      tap(trips => this.tripsSubject$.next(trips)),
      map(() => undefined),
    );
  }

  private updateTripIfBookingReadyServerAction(serverAction: BookingReadyServerAction | null): void {
    if (serverAction?.actionType === ServerActionType.BookingReady) {
      const bookings = this.tripsSubject$.getValue();
      const bookingIndex = bookings.findIndex(booking => booking.hash === serverAction.payload.booking_hash);
      if (bookingIndex > -1) {
        this.patchTrip(serverAction, bookings, bookingIndex);
      }
    }
  }

  private patchTrip(serverAction: BookingReadyServerAction, bookings: BookingSimple[], bookingIndex: number): void {
    this.tripService
      .getTrip$(serverAction.payload.booking_hash)
      .pipe(
        tap(booking => {
          const updatedBooking = {
            ...bookings[bookingIndex],
            canNavigate: true,
            isBeingGenerated: false,
            publicStatus: booking.publicStatus,
            status: booking.status,
          };
          const updatedBookings = [
            ...bookings.slice(0, bookingIndex),
            updatedBooking,
            ...bookings.slice(bookingIndex + 1),
          ];
          this.tripsSubject$.next(updatedBookings);
        }),
        take(1),
      )
      .subscribe();
  }

  private isPastTrip(booking: BookingSimple): boolean {
    return (
      !!booking.endDate &&
      new Date() > new Date(booking.endDate) &&
      booking.publicStatus.value !== PublicStatusType.FUTURE
    );
  }

  private isUpcomingTrip(booking: BookingSimple): boolean {
    return (
      !!booking.endDate &&
      new Date() <= new Date(booking.endDate) &&
      booking.publicStatus.value !== PublicStatusType.FUTURE
    );
  }

  private isFutureTrip(booking: BookingSimple): boolean {
    return booking.publicStatus.value === PublicStatusType.FUTURE;
  }
}
