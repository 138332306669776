/* TripStatus - PublicStatus mapping:
"trip-requested" or "designing-itinerary" -> "building"
"pending-select-sample" -> "pending"
"itinerary-delivered" or "partially-booked" or "booking-requested" -> "planning"
"itinerary-finalized" -> "ready"
"closed" -> "concluded"
 */
export enum PublicStatusType {
  BUILDING = 'building',
  CONCLUDED = 'concluded',
  FUTURE = '',
  PLANNING = 'planning',
  READY = 'ready',
  PENDING = 'pending',
}
