import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { HttpListResponse } from '@ess-front/shared';
import { BookingSimpleBE } from '@shared/models/members-area/booking-simple-be.model';
import { BookingSimple } from '@shared/models/members-area/booking-simple.model';
import { PublicStatusType } from '@shared/models/public-status-type.model';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  private readonly apiURL = `${environment.apiEnv}api/v2/`;

  constructor(private httpClient: HttpClient) {}

  getTrips$(): Observable<BookingSimple[]> {
    return this.httpClient.get<HttpListResponse<BookingSimpleBE>>(`${this.apiURL}bookings/?limit=1000`).pipe(
      map(response => response.results),
      map(bookings => bookings.map((booking: BookingSimpleBE) => this.transformIntoBookingSimple(booking))),
    );
  }

  private transformIntoBookingSimple(booking: BookingSimpleBE): BookingSimple {
    return {
      adults: booking.adults,
      canNavigate: this.getCanNavigate(booking.public_status.value),
      children: booking.children,
      endDate: booking.end_date,
      hash: booking.hash,
      id: booking.id,
      image: booking.image,
      infants: booking.infants,
      isBeingGenerated: booking.is_being_generated,
      opportunityDate: booking.opportunity_date,
      publicStatus: booking.public_status,
      startDate: booking.start_date,
      statusType: booking.status,
      title: booking.title,
      totalTravelers: this.getTotalTravelers(booking),
      tripDuration: this.getTripDuration(booking),
    };
  }

  private getTotalTravelers(booking: BookingSimpleBE): number {
    return booking.adults + booking.infants + booking.children;
  }

  private getTripDuration(booking: BookingSimpleBE): number | null {
    const MILISECONDS_ON_A_DAY = 1000 * 60 * 60 * 24;
    const { end_date, start_date } = booking;
    if (!end_date || !start_date) return null;

    const end = new Date(end_date).valueOf();
    const start = new Date(start_date).valueOf();
    return Math.floor((end - start) / MILISECONDS_ON_A_DAY + 1);
  }

  private getCanNavigate(publicStatusType: PublicStatusType): boolean {
    return publicStatusType !== PublicStatusType.FUTURE && publicStatusType !== PublicStatusType.BUILDING;
  }
}
